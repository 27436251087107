.filter-btn {
    background-color: #008CBA; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.filter-btn:focus {
    background-color: rgb(2, 165, 219);
    padding: 16px 33px;
}

.order-btn {
    background-color: rgb(2, 165, 219);
    border: none;
    color: white;
    padding: 16px 33px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
}

.order-btn:hover{
    background-color: rgb(2, 145, 150);
    padding: 16px 33px;
}

.order-btn:active {
    background: green;
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    outline: none;
}

.courier-info{
    text-align: center;
    font-weight: bold;
    color: firebrick;
}

.job-table-container.table-title {
    display: block;
    margin: auto;
    max-width: 600px;
    padding: 5px;
    width: 100%;
}

.job-table-container .table-title h3 {
    color: #fafafa;
    font-size: 30px;
    font-weight: 400;
    font-style: normal;
    font-family: "Roboto", helvetica, arial, sans-serif;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
}


/*** Table Styles **/

.job-table-container .table-fill {
    background: white;
    border-radius: 3px;
    border-collapse: collapse;
    height: 320px;
    margin: auto;
    max-width: 600px;
    padding: 5px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    animation: float 5s infinite;
}

.job-table-container th {
    color: #D5DDE5;;
    background: #1b1e24;
    border-bottom: 4px solid #9ea7af;
    border-right: 1px solid #343a45;
    font-size: 1rem;
    font-weight: 100;
    padding: 24px;
    text-align: left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align: middle;
}

.job-table-container th:first-child {
    border-top-left-radius: 3px;
}

.job-table-container th:last-child {
    border-top-right-radius: 3px;
    border-right: none;
}

.job-table-container tr {
    border-top: 1px solid #C1C3D1;
    border-bottom: 1px solid #C1C3D1;
    font-size: 1rem;
    font-weight: normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}

.job-table-container tr:hover td {
    background: #4E5066;
    color: #FFFFFF;
    border-top: 1px solid #22262e;
}

.job-table-container tr:first-child {
    border-top: none;
}

.job-table-container tr:last-child {
    border-bottom: none;
}

.job-table-container tr:nth-child(odd) td {
    background: #EBEBEB;
}

.job-table-container tr:nth-child(odd):hover td {
    background: #4E5066;
}

.job-table-container tr:last-child td:first-child {
    border-bottom-left-radius: 3px;
}

.job-table-container tr:last-child td:last-child {
    border-bottom-right-radius: 3px;
}

.job-table-container td {
    background: #FFFFFF;
    padding: 20px;
    text-align: left;
    vertical-align: middle;
    font-weight: 300;
    font-size: 1rem;
    text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    border-right: 1px solid #C1C3D1;
}

.job-table-container td:last-child {
    border-right: 0px;
}

.job-table-container th.text-left {
    text-align: left;
}

.job-table-container th.text-center {
    text-align: center;
}

.job-table-container th.text-right {
    text-align: right;
}

.job-table-container td.text-left {
    text-align: left;
}

.job-table-container td.text-center {
    text-align: center;
}

.job-table-container td.text-right {
    text-align: right;
}
